export const LOGGED_IN_LANDING_ROUTE = "/"
export const LOGIN_PAGE_ROUTE = "/login"
export const DEFAULT_LANDING_ROUTE = LOGIN_PAGE_ROUTE
export const DEFAULT_PAGINATION_LIMIT = 10;

export const CASE_STATUS_CHOICES = ['Ongoing', 'Completed'];

export const WEEK_DAY_CHOICES = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const getFileNameFromUrl = (fileUrl) => {
    return fileUrl.split('/').slice(-1).toString().split("?")[0]
}

export const COLORS_LIST = [
    ['#4eea9e', '#277c53'],
    ['#e4cdf5', '#795691'],
    ['#a1c8f1', '#2a66a4'],
    ['#bde1a5', '#4a7929'],
    ['#f3af67', '#965a19'],
    ['#d9f587', '#728c27'],
    ['#bed5f8', '#4f70a2'],
    ['#f38d75', '#ad3114'],
    ['#d9d9f3', '#78788d'],
    ['#d0cfcf', '#3f3e3e'],
]
