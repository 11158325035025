import {createSlice} from '@reduxjs/toolkit'
import {hasValidApiToken} from "../utils/auth";

export const commonSlice = createSlice({
    name: 'common',
    initialState: {
        loggedIn: false,
        profileData: null,
    },
    reducers: {
        updateLoginState: (state) => {
            state.loggedIn = hasValidApiToken()
        },
        updateProfileData: (state, data) => {
            state.profileData = data.payload;
        },
    },
});

export const {updateLoginState, updateProfileData} = commonSlice.actions;

export default commonSlice.reducer;
